import React from 'react';
import {
  MobileDrawer,
  Card,
  IconButton,
  MobileDrawerPosition,
} from 'wix-ui-tpa/cssVars';
import Text, { TextType } from '../../../Text/Text';
import { ModalContent } from '../ModalContent/ModalContent';
import { ButtonProp, ModalFooter } from '../ModalFooter/ModalFooter';
import { classes, st } from './MobileModal.st.css';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import { MobileModalDataHooks } from './dataHooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ServiceData } from '../../../../../utils/state/types';
import { FormSelectedSlot } from '../../../../../types/formSelectedSlots';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';

export interface MobileModalProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  dataHook: string;
  dateRegionalSettingsLocale: string;
  primaryButton?: ButtonProp;
  secondaryButton?: ButtonProp;
  isOpen: boolean;
  onClose: () => {};
  onOverlayClose: () => {};
  title: string;
}

export const MobileModal: React.FC<MobileModalProps> = ({
  formSelectedSlot,
  serviceData,
  dateRegionalSettingsLocale,
  primaryButton,
  secondaryButton,
  isOpen,
  onClose,
  onOverlayClose,
  title,
  dataHook,
}) => {
  const { isRTL } = useEnvironment();
  const { isUpsellPluginInstalled } = useFormComponentContext();

  return (
    <MobileDrawer
      isFullScreen={isUpsellPluginInstalled}
      data-hook={dataHook}
      isOpen={isOpen}
      className={st(classes.root, { rtl: isRTL })}
      onRequestClose={onOverlayClose}
      position={MobileDrawerPosition.Bottom}
    >
      <IconButton
        data-hook={MobileModalDataHooks.CLOSE_BUTTON}
        icon={<Close />}
        className={classes.closeButton}
        onClick={onClose}
      />
      <Card stacked className={classes.contentWrapper}>
        <Card.Container>
          <Text type={TextType.Title} className={classes.title}>
            {title}
          </Text>
          <div className={classes.contentContainer}>
            <ModalContent
              formSelectedSlot={formSelectedSlot}
              serviceData={serviceData}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            />
          </div>
        </Card.Container>
        <Card.Container
          className={[
            classes.footerWrapper,
            ...(isUpsellPluginInstalled ? [classes.footerMargin] : []),
          ].join(' ')}
        >
          <ModalFooter
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
          />
        </Card.Container>
      </Card>
    </MobileDrawer>
  );
};
