import React, { useEffect } from 'react';
import {
  PaymentOption,
  BorderStyle,
  PaymentType,
  ReservedPaymentOptionLabels,
  ReservedPaymentOptionIds,
  PaymentMethod,
} from '../../../../../../types/types';
import {
  Dropdown,
  DropdownTheme,
  DropdownOptionProps,
  DropdownOptionTextOverflow,
} from 'wix-ui-tpa/cssVars';
import { SlotService } from '../../../../../../utils/state/types';
import { classes, st } from './ServicePaymentDropdown.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import { ServicePaymentDropdownHooks } from './dataHooks';
import { getServiceSlotIdentifier } from '../../../../../../utils';
import { useFormActions } from '../../../../Hooks/useFormActions';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { getContent } from '../../../../../../utils/content/content';
import Text, { TextType } from '../../../../Text/Text';

export type ServicePaymentDropdownOptionProps = {
  service: SlotService;
  paymentOptions: PaymentOption[];
  label: string;
};

export const ServicePaymentDropdown: React.FC<
  ServicePaymentDropdownOptionProps
> = ({ service, paymentOptions, label }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { setServiceSelectedPaymentOption } = useFormActions();
  const settings = useSettings();
  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const buildPaymentOptionId = (paymentOption: PaymentOption) => {
    return `${paymentOption.id}:${paymentOption.label}`;
  };
  const getPaymentOptionLabel = (paymentOption: PaymentOption) => {
    let optionLabel;
    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        if (paymentOption.label === ReservedPaymentOptionLabels.ONLINE) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOnlineText,
          });
        } else if (
          paymentOption.label === ReservedPaymentOptionLabels.OFFLINE
        ) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOfflineText,
          });
        } else if (paymentOption.label === ReservedPaymentOptionLabels.FREE) {
          optionLabel = t('app.booking-details.payment-options.free.label');
        } else {
          optionLabel = paymentOption.label;
        }
        break;
      }
      case PaymentType.BUY_PLAN:
      case PaymentType.USE_PLAN: {
        optionLabel = paymentOption.label;
        break;
      }
      default:
        optionLabel = paymentOption.label;
    }

    return optionLabel.toString();
  };

  const getPaymentSectionTitle = (paymentOption: PaymentOption) => {
    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        return t(
          'app.bookings-form.payment-section.service-dropdown.pay-for-this-session-section-title',
        );
      }
      case PaymentType.BUY_PLAN: {
        return t(
          'app.bookings-form.payment-section.service-dropdown.buy-a-plan-section-title',
        );
      }
      case PaymentType.USE_PLAN: {
        return t(
          'app.bookings-form.payment-section.service-dropdown.use-my-plan-section-title',
        );
      }
    }
  };

  const shouldShowSectionTitle = (currentOption: PaymentOption) => {
    if (currentOption.type === PaymentType.SINGLE_SESSION) {
      return !!paymentOptions.find(
        (o) => o.type !== PaymentType.SINGLE_SESSION,
      );
    } else {
      return true;
    }
  };

  const getPaymentOptionsDropdownOptions = (options: PaymentOption[]) => {
    let lastPaymentOptionType: PaymentType | undefined;
    const results: DropdownOptionProps[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (options.length > 1) {
        if (option.type !== lastPaymentOptionType) {
          if (i !== 0) {
            results.push({ divider: true });
          }
          if (shouldShowSectionTitle(option)) {
            results.push({
              id: `section-${option.type}`,
              value: getPaymentSectionTitle(option),
              textOverflow: DropdownOptionTextOverflow.wrap,
              isSelectable: false,
              isSectionTitle: true,
            });
          }
        }
      }
      results.push({
        id: buildPaymentOptionId(option),
        value: getPaymentOptionLabel(option),
        subtitle: option.pricingDetails,
        textOverflow: DropdownOptionTextOverflow.wrap,
        isSelectable: true,
      });
      lastPaymentOptionType = option.type;
    }

    return results;
  };

  const options: DropdownOptionProps[] = getPaymentOptionsDropdownOptions(
    paymentOptions || [],
  );

  const findSelectedPaymentOption = (id: string) => {
    const [paymentOptionId, optionLabel] = id.split(/:(.*)/s);

    return (paymentOptions || []).find((paymentOption) => {
      return (
        paymentOption.id === paymentOptionId &&
        paymentOption.label === optionLabel
      );
    })!;
  };

  const getSelectedId = () => {
    const selectedPaymentOptionId = service.selectedPaymentOption.id;
    const selectedPaymentType = service.selectedPaymentType;
    let foundOption;
    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      foundOption = paymentOptions.find(
        (option) => option.type === PaymentType.BUY_PLAN,
      );
    } else if (
      selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession
    ) {
      if (selectedPaymentType === PaymentMethod.ONLINE) {
        foundOption = paymentOptions.find(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.ONLINE,
        );
      } else if (selectedPaymentType === PaymentMethod.OFFLINE) {
        foundOption = paymentOptions.find(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.OFFLINE,
        );
      }
    } else {
      foundOption = paymentOptions.find(
        (option) => option.id === selectedPaymentOptionId,
      );
    }

    if (foundOption) {
      return buildPaymentOptionId(foundOption);
    }
  };

  const [selectedId, setSelectedId] = React.useState<string>();

  useEffect(() => {
    setSelectedId(
      getSelectedId() || options.filter((o) => o.isSelectable)[0]?.id!,
    );
  }, [service.selectedPaymentOption.id, service.selectedPaymentType]);

  const onChange = async (option: DropdownOptionProps) => {
    setSelectedId(option.id!);
    setServiceSelectedPaymentOption(
      service,
      findSelectedPaymentOption(option.id!),
    );
  };

  if (!selectedId) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        isMobile,
        theme,
      })}
    >
      <p>
        <Text
          key={getServiceSlotIdentifier(service.nestedSlot)}
          data-hook={`${
            ServicePaymentDropdownHooks.SingleOptionAsLabelServiceName
          }_${getServiceSlotIdentifier(service.nestedSlot)}`}
          className={st(classes.dropdown, { theme })}
          type={TextType.Primary}
        >
          {label}
        </Text>
      </p>

      <Dropdown
        key={getServiceSlotIdentifier(service.nestedSlot)}
        data-hook={`${
          ServicePaymentDropdownHooks.Dropdown
        }_${getServiceSlotIdentifier(service.nestedSlot)}`}
        className={st(classes.dropdown, { theme })}
        options={options}
        initialSelectedId={selectedId}
        onChange={onChange}
      />
    </div>
  );
};
