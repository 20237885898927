import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { ServicePayment, OfferedAsType } from '@wix/bookings-uou-types';
import {
  PaymentMethod,
  PaymentOption,
  PaymentType,
  PlanWithDescription,
  ReservedPaymentOptionIds,
  ReservedPaymentOptionLabels,
  TFunction,
} from '../../../types/types';
import { Membership } from '@wix/ambassador-memberships-spi-host/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';

export const isOfferedAsOneTimeOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length === 1 && isOfferedAsOneTime(servicePayment)
  );
};

export const isOfferedAsOneTime = (servicePayment: ServicePayment) => {
  return servicePayment.offeredAs.includes(OfferedAsType.ONE_TIME);
};

export const isOfferedAsPricingPlanOnly = (servicePayment: ServicePayment) => {
  return (
    servicePayment.offeredAs.length <= 1 &&
    isServiceHavePricingPlans(servicePayment)
  );
};

export const isServiceHavePricingPlans = (servicePayment: ServicePayment) => {
  return servicePayment.pricingPlanInfo?.pricingPlans?.length! > 0;
};

export const isOfferedAsPricingPlanOnlyWithoutPlansConnected = (
  servicePayment: ServicePayment,
) => {
  return (
    servicePayment.offeredAs.length === 1 &&
    servicePayment.offeredAs[0] === OfferedAsType.PRICING_PLAN &&
    !isServiceHavePricingPlans(servicePayment)
  );
};

export const createBuyPlanOption = (plan: PlanWithDescription) => {
  return {
    id: plan.id!,
    label: plan.name!,
    disabled: false,
    type: PaymentType.BUY_PLAN,
    pricingDetails: plan.description,
  };
};

export const createPayOfflineOption = (
  price: string,
  t: TFunction,
): PaymentOption => {
  const payOfflineOption: PaymentOption = {
    id: ReservedPaymentOptionIds.SingleSession,
    label: ReservedPaymentOptionLabels.OFFLINE,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };

  payOfflineOption.pricingDetails = t(
    'app.bookings-form.payment-section.service-dropdown.pay-offline-subtitle',
    { price },
  );

  return payOfflineOption;
};

export const createPayOnlineOption = (
  price: string,
  t: TFunction,
): PaymentOption => {
  const payOnlineOption: PaymentOption = {
    id: ReservedPaymentOptionIds.SingleSession,
    label: ReservedPaymentOptionLabels.ONLINE,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };

  payOnlineOption.pricingDetails = t(
    'app.bookings-form.payment-section.service-dropdown.pay-online-subtitle',
    { price },
  );

  return payOnlineOption;
};

export const createFreeOption = (): PaymentOption => {
  return {
    id: ReservedPaymentOptionIds.SingleSession,
    label: ReservedPaymentOptionLabels.FREE,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };
};

export const createCustomPriceOption = (customPrice: string): PaymentOption => {
  return {
    id: ReservedPaymentOptionIds.SingleSession,
    label: customPrice,
    disabled: false,
    type: PaymentType.SINGLE_SESSION,
  };
};

export function createUsePlanOption(
  membership: Membership,
  numberOfParticipants: number,
  benefitsWithPlanInfo: BenefitWithPlanInfo[] | undefined,
  t: TFunction,
  dateAndTimeFormatter: DateTimeFormatter,
): PaymentOption {
  const disabled = isDisabledMembership({
    membership,
    numberOfParticipants,
  });
  const validUntil = membership.expirationDate
    ? t('app.payment.valid-until.text', {
        validUntil: dateAndTimeFormatter.formatDate(
          membership.expirationDate.toISOString(),
        ),
      })
    : undefined;
  const creditRemain = membership?.credits?.remaining || undefined;
  const planUsage =
    membership?.credits?.remaining && membership?.credits?.total
      ? `${membership.credits.remaining}/${membership.credits.total}`
      : undefined;
  const pricingDetails = [validUntil, planUsage].filter((x) => x).join(', ');
  return {
    id: membership.id!,
    label: membership.name?.original!,
    benefitInfo: findBenefitWithPlanInfo(
      membership.additionalData?.benefitId,
      benefitsWithPlanInfo,
    ),
    disabled,
    ...(pricingDetails ? { pricingDetails } : {}),
    ...(planUsage ? { suffix: planUsage } : {}),
    ...(validUntil ? { validUntil } : {}),
    ...(creditRemain ? { creditRemain } : {}),
    type: PaymentType.USE_PLAN,
  };
}

export const findBenefitWithPlanInfo = (
  benefitId?: string,
  benefitsWithPlanInfo?: BenefitWithPlanInfo[],
) => {
  return benefitsWithPlanInfo?.find((item) => item?.benefit?.id === benefitId);
};

export const isDisabledMembership = ({
  membership,
  numberOfParticipants,
}: {
  membership: Membership;
  numberOfParticipants: number;
}) => {
  const isUnlimitedMembership = !membership?.credits?.remaining;
  return (
    Number(membership?.credits?.remaining) < numberOfParticipants ||
    (isUnlimitedMembership && numberOfParticipants > 1)
  );
};

export const getPaymentTypeFromOption = (
  paymentOption: PaymentOption,
): PaymentMethod => {
  if (paymentOption.type === PaymentType.USE_PLAN) {
    return PaymentMethod.MEMBERSHIP;
  } else if (paymentOption.type === PaymentType.BUY_PLAN) {
    return PaymentMethod.MEMBERSHIP;
  } else if (
    paymentOption.type === PaymentType.SINGLE_SESSION &&
    paymentOption.label === ReservedPaymentOptionLabels.ONLINE
  ) {
    return PaymentMethod.ONLINE;
  }

  return PaymentMethod.OFFLINE;
};
