import {
  DurationMapperOptions,
  DurationFormatter,
} from '@wix/bookings-date-time';
import { TFunction } from '../../types/types';

type SlotDuration = {
  durationText?: string;
  durationAriaText?: string;
};

export const getFormattedDuration = ({
  startDate,
  endDate,
  t,
  isShowDurationInDaysEnabled,
}: {
  startDate?: string;
  endDate?: string;
  t: TFunction;
  isShowDurationInDaysEnabled?: boolean;
}): SlotDuration => {
  const durationFormatter = new DurationFormatter(t);
  const durationInMS =
    startDate && endDate
      ? durationFormatter.getDurationInMs(startDate, endDate)
      : '';
  const durationOptions: DurationMapperOptions = {
    ...(isShowDurationInDaysEnabled
      ? { dayUnit: 'app.booking-details.duration.units.days' }
      : {}),
    hourUnit: 'app.booking-details.duration.units.hours',
    minuteUnit: 'app.booking-details.duration.units.minutes',
  };
  const ariaDurationOptions: DurationMapperOptions = {
    ...(isShowDurationInDaysEnabled
      ? { dayUnit: 'app.booking-details.duration.units.aria-days' }
      : {}),
    hourUnit: 'app.booking-details.duration.units.aria-hours',
    minuteUnit: 'app.booking-details.duration.units.aria-minutes',
  };
  const durationText = durationInMS
    ? durationFormatter.formatDurationFromMS(durationInMS, durationOptions)
    : '';
  const durationAriaText = durationInMS
    ? durationFormatter.formatDurationFromMS(durationInMS, ariaDurationOptions)
    : '';
  return {
    durationText,
    durationAriaText,
  };
};

export const getCourseTotalNumberOfSessionsDurationText = (
  totalNumberOfSessions: number,
  t: TFunction,
): SlotDuration => {
  const duration = t('app.booking-details.course.duration.text', {
    sessions: totalNumberOfSessions,
  });
  return {
    durationText: duration,
    durationAriaText: duration,
  };
};
