import {
  CouponsErrorType,
  CreateBookingErrorType,
  FormError,
  FormErrorsType,
  GenericErrorType,
  PreviewErrorType,
} from '../../types/errors';
import { PlatformServerError } from '../../../__tests__/mocks/ambassador/mockPlatformError';
import { mapToArray } from '../index';

export const getErrorByType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType?: ErrorType;
  errors?: FormError[];
}): FormError =>
  errors?.find((error) =>
    mapToArray<FormErrorsType>(errorType as any).includes(error.errorType),
  )!;

const isErrorOfType = <ErrorType>(errorType: ErrorType, error: FormError) => {
  return mapToArray<FormErrorsType>(errorType as any).some(
    (expectedError) => error.errorType === expectedError,
  );
};

export const hasErrorOfType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType: ErrorType;
  errors: FormError[];
}): boolean => {
  return errors.some((error) => isErrorOfType(errorType, error));
};

export function mapCouponServerError(
  couponServerError: string,
): CouponsErrorType {
  if (
    couponServerError.includes('id is not a valid GUID') ||
    couponServerError.includes('ERROR_INVALID_PRODUCTS')
  ) {
    return CouponsErrorType.NOT_VALID_FOR_SELECTED_SERVICE;
  } else if (couponServerError.includes('ERROR_COUPON_HAS_EXPIRED')) {
    return CouponsErrorType.COUPON_HAS_EXPIRED;
  } else if (couponServerError.includes('ERROR_COUPON_USAGE_EXCEEDED')) {
    return CouponsErrorType.COUPON_USAGE_EXCEEDED;
  } else if (couponServerError.includes('ERROR_COUPON_SERVICE_UNAVAILABLE')) {
    return CouponsErrorType.COUPON_SERVICE_UNAVAILABLE;
  } else if (couponServerError.includes('ERROR_COUPON_DOES_NOT_EXIST')) {
    return CouponsErrorType.COUPON_DOES_NOT_EXIST;
  } else if (
    couponServerError.includes('ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED')
  ) {
    return CouponsErrorType.COUPON_LIMIT_PER_CUSTOMER_EXCEEDED;
  }
  return CouponsErrorType.GENERAL_SERVER_ERROR;
}

export function mapPreviewServerError(
  previewServerError: string,
): PreviewErrorType | GenericErrorType {
  if (previewServerError.includes(PreviewErrorType.INVALID_LINE_ITEM)) {
    return PreviewErrorType.INVALID_LINE_ITEM;
  } else if (
    previewServerError.includes(PreviewErrorType.FAILED_RESOLVING_SERVICE)
  ) {
    return PreviewErrorType.FAILED_RESOLVING_SERVICE;
  } else if (
    previewServerError.includes(
      PreviewErrorType.NUMBER_OF_PARTICIPANTS_NOT_FOUND,
    )
  ) {
    return PreviewErrorType.NUMBER_OF_PARTICIPANTS_NOT_FOUND;
  }
  return GenericErrorType.GENERIC_BOOK_ERROR;
}

export function mapCouponServerErrorsAndReporter(couponServerError: string): {
  error: CouponsErrorType;
  shouldReport: boolean;
} {
  const error = mapCouponServerError(couponServerError);
  let shouldReport = false;
  if (
    error === CouponsErrorType.COUPON_SERVICE_UNAVAILABLE ||
    error === CouponsErrorType.GENERAL_SERVER_ERROR
  ) {
    shouldReport = true;
  }

  return {
    error,
    shouldReport,
  };
}

export type BookingsServerError = {
  details: {
    applicationError: {
      code: CreateBookingErrorType;
      data?: {
        violations?: [{ description?: string }];
      };
    };
  };
};

export function mapCheckoutBookingError(error: PlatformServerError) {
  const errorCode = error?.details?.applicationError?.code;
  if (
    isErrorOfType(CouponsErrorType, {
      errorType: errorCode as FormErrorsType,
    }) ||
    isErrorOfType(CreateBookingErrorType, {
      errorType: errorCode as FormErrorsType,
    })
  ) {
    return errorCode;
  } else {
    return GenericErrorType.GENERIC_BOOK_ERROR;
  }
}
